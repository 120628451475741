<template lang="pug">
    .main-wrapper.grupo-procedimentos-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "grupo-procedimentos" }'> Grupos Procedimentos</router-link> /
                            <b>{{ model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm || waiting' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } grupo-procedimentos`")
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.ds_codigo.$error }")
                                label.form-label Codigo:
                                InputText(type='text' v-model='$v.model.ds_codigo.$model')
                                .feedback--errors(v-if='submitted && $v.model.ds_codigo.$error')
                                    .form-message--error(v-if="!$v.model.ds_codigo.minLength") <b>Codigo</b> deve ter pelo menos 1 caracter.
                                    .form-message--error(v-if="!$v.model.ds_codigo.required") <b>Codigo</b> é obrigatório.

                            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.ds_descricao.$error }")
                                label.form-label Descrição:
                                InputText(type='text' v-model='$v.model.ds_descricao.$model')
                                .feedback--errors(v-if='submitted && $v.model.ds_descricao.$error')
                                    .form-message--error(v-if="!$v.model.ds_descricao.minLength") <b>Descrição</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.ds_descricao.required") <b>Descrição</b> é obrigatório.

                            .p-col-12.p-md-6
                                span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                            .p-col-12.p-md-6
                                span <b>Data da criação: </b> {{ model.dt_criado_f }}
                            .p-col-12.p-md-6
                                span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                            .p-col-12.p-md-6
                                span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.ta-right
                                Button(label='Salvar' type="submit" style='max-width:160px')

</template>

<style lang="scss">
    .grupo-procedimentos-salvar {

        .waitingGrupoProcedimentos-wrapper {
            text-align: center;
            padding: 80px 0;
            .p-progress-spinner {
                width: 60px;
                height: auto;
            }
        }
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }

        .p-dialog{overflow:visible;width:98%;max-width:600px}
        .p-dialog .p-dialog-content{overflow:visible !important}
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import PickList from 'primevue/picklist'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import Paginator from 'primevue/paginator'
    import Dialog from 'primevue/dialog'
    import BuscarPaciente from '../Paciente/BuscarPaciente'



    import { GrupoProcedimentos } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import { required, minLength } from 'vuelidate/lib/validators'
    import moment from "moment"

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, ProgressSpinner,
                InputMask, SelectButton, Dropdown, Tooltip, PickList, DataTable, Column,
                Paginator, Dialog, BuscarPaciente
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true

            let id = 0
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id != 0) {
                    GrupoProcedimentos.find(id).then(response => {
                        if (response.status == 200) {
                            let keys = Object.keys(this.model)
                            keys.forEach(key => this.model[key] = response.data[key])
                            this.model.id = id
                            this.model.dt_criado_f = response.data.dt_criado ?
                                moment(response.data.dt_criado).format('DD/MM/YYYY HH:mm') : ""
                            this.model.dt_atualizado_f = response.data.dt_atualizado ?
                                moment(response.data.dt_atualizado).format('DD/MM/YYYY HH:mm') : ""
                        }
                        this.waiting = false
                    })

            } else this.waiting = false
        },
        data () {
            return {
                model: {
                    ds_descricao: null,
                    ds_codigo: null,
                    nm_usuario_cri: '',
                    nm_usuario_edi: '',
                },
                filters: {
                    ds_descricao: '',
                    ds_codigo: ''
                },
                options: {
                },
                waiting: true,
                waitingForm: false,
                waitingApagar: false,
                submitted: false,
                dialogApagar: false,
                dialogApagar_data: {},
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        validations () {
            let v = {
                model: {
                    ds_descricao: { required, minLength: minLength(2) },
                    ds_codigo: { required, minLength: minLength(1) }
                }
            }

            return v
        },
        methods: {
            handleSubmit () {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)

                this.waitingForm = true
                GrupoProcedimentos.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'grupo-procedimentos' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        else Object.values(response.data).forEach(errorMsg => {
                                if (typeof errorMsg == 'object')
                                    this.$toast.error(errorMsg[0], { duration: 3000 })
                                else this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
            },
        }
    }
</script>